import { defineStore } from 'pinia'
import  axios from 'axios'

const useUserStore = defineStore('user',{
    id:'user',
    state() {return{
        name:'Name not available',
        id_user:'ID not available',
        email:'Email not available',
        pword:'Password not available',
        role:'Role not available'
    }},
    actions:{
        async getUser(){
            let api = '/identity/config'  
            // let api = '/config.json'
            try{
                let result = await axios.get(api)
                this.name = result.data.userdets.name
                this.id_user = result.data.userdets.id_user
                this.email = result.data.userdets.email
                this.pword = result.data.userdets.pword
                this.role = result.data.userdets.role
                // console.log('user2 = ',result)
                return result.data.userdets
            } catch(err){
                this.user = err.message
                console.log("err.message ",err.message)
            }
        }
    },
    getters:{
        getName(state){
            return state.name
        },
        getIduser(state){
            return state.id_user
        },
        getEmail(state){
            return state.email
        },
        getPword(state){
            return state.pword
        },
        getRole(state){
            return state.role
        }
    }
})

export { useUserStore }