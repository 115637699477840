import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
    apiKey: "AIzaSyC3OSg7hc3NLBeAkvZR_bgBlvkGnQDQvhM",
    authDomain: "franchise-marketing-forum.firebaseapp.com",
    projectId: "franchise-marketing-forum",
    storageBucket: "franchise-marketing-forum.appspot.com",
    messagingSenderId: "233548867066",
    appId: "1:233548867066:web:f102126ece15c08d21b043",
    measurementId: "G-HR9CTLGL3V"
  };

  // init firebase
  firebase.initializeApp(firebaseConfig)

  // init services
  const projectFirestore = firebase.firestore()
  const projectStorage = firebase.storage()

  // timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, projectStorage, timestamp }
