import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Home from '../views/Home.vue'
import CreateTopic from '../views/topics/CreateTopic.vue'
import TopicDetails from '../views/topics/TopicDetails.vue'
import PageNotFound from '../components/PageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/index/process',
    name: 'Home2',
    component: Home
  },
  {
    path: '/topics/create',
    name: 'CreateTopic',
    component: CreateTopic
  },
  {
    path: '/topics/:id/opt/:lr?',
    name: 'TopicDetails',
    component: TopicDetails,
    props: true
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
    component:PageNotFound
  } 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router);

export default router
