import { ref } from 'vue'

const urlStripped = () => {
    // console.log(coverUrl);

    const newDocs = []

    const addThumbs = (documents) => {
        // console.log("documents",documents.value)

        let counter = 0
            documents.value.forEach((document) => {
                let url = new URL(document.coverUrl);

                // console.log("host",url.host)
                // console.log("href",url.href)
                // console.log("origin",url.origin)
                // console.log("password",url.password)
                // console.log("pathname",url.pathname)
                // console.log("port",url.port)
                // console.log("search",url.search)
                // console.log("searchParams",url.searchParams)
                // console.log("",url.)

                let stringToSearch = url.pathname 
                let firstmarker = stringToSearch.indexOf('%2F')

                let firstpart = stringToSearch.substring(0,firstmarker)

                let secondpart = stringToSearch.substring(firstmarker+3)

                document.thumbnail = url.origin + firstpart + "%2F" + "thumb_" + secondpart + url.search;
                document.counter = counter
                newDocs.push(document)
                counter++
            });

            newDocs.reverse()
            // console.log("newDocs",[newDocs])
        // debugger
    }

    return { newDocs, addThumbs }
  
  }

  export default urlStripped
    
    
    
    
    
  
