<template>
<div class="ui icon input" style="width: 100%">
      <input type="text" placeholder="Search..."  v-model="searchQuery" />
      <i class="search icon"></i>
  </div>

  <!-- <div v-for="(topic,ind) in searchedTopics" :key="topic.id"> -->
  <div v-for="(topic) in searchedTopics" :key="topic.id">
    <router-link :to="{ name: 'TopicDetails', params: { id: topic.id } }" @click="topicClicked(topic.title)">
      <!-- <div v-if="ind <=3" class="single flex-container"> -->
      <div class="single flex-container">
          <div class="fthumbnail">
              <img v-if="topic.thumbnail" :src="topic.thumbnail">
              <img v-else :src="topic.coverUrl">
          </div>
          <div class="info">
              <!-- <h4>{{ ind }}</h4> -->
              <h5>{{ topic.title }}</h5>
              <p class="createdby">Created by {{ topic.userName }}</p>
              <p class="createdat">{{ topic.createdAt }}</p>
          </div>
          <div class="comment-number">
              <p>{{ topic.comments.length }} comments</p>
          </div>
        </div>
        <!-- <div class="tier-2 single-secondary" v-else>
          <h5><span class="tier-2 heading">{{ topic.title }}</span>
          <span class="createdby">Created by {{ topic.userName }}</span>
          <span class="createdat">{{ topic.createdAt }}</span>
          <span class="comment-number">{{ topic.comments.length }} comments</span>
          </h5>
        </div> -->
    </router-link>
  </div>
</template>

<script>

import { ref } from '@vue/reactivity'
import { computed, toRef } from 'vue';
import { useGtag } from "vue-gtag-next";
import { event } from 'vue-gtag-next'

export default {
    props: ['topics'],
    setup(props, context) {
      const topicsList = toRef(props,'topics')
      const searchQuery = ref("");
      const { event } = useGtag()

      // console.log('topicsList = ', [topicsList])
      
      const searchedTopics = computed(() => {
        return topicsList.value.filter((topic) => {
          return (
            topic.title
              .toLowerCase()
              .indexOf(searchQuery.value.toLowerCase()) != -1
          );
        });
      });
      
      // console.log('searchedTopics = ', [searchedTopics])

      const topicClicked = ((topicName) => {
        // console.log('select_item = ', topicName);
          event('select_item', {
            'item_name': topicName
        });
      });


      return { searchQuery, searchedTopics, topicClicked }
    }
    
}
</script>

<style scoped>


  .single, .single-secondary {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: white;
    transition: all ease 0.2s;
  }

  .single {
    padding: 6px 20px 6px 6px;
    margin: 16px 0;
  }

  .single-secondary {
      padding: 0.4rem 20px 0.4rem 6px;
      margin: 1rem 0;
    }

  .single:hover, .single-secondary:hover {
    box-shadow: 1px 2px 3px rgba(50,50,50,0.05);
    transform: scale(1.02);
    transition: all ease 0.2s;
  }
  .fthumbnail {
    max-width: 150px;
    max-height: 100px;
    overflow: hidden;
    border-radius: 10px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: scale-down;
    object-position: center top;  
  }
  .info {
    margin: 0 30px;
  }
  .comment-number {
    margin-left: auto;
    font-size: 0.83rem;
  }

  .single .info h5 {
    font-size: 1.85rem;
    color: #0019a5;
  }
  
  .single .info p.createdby {
    font-size: 1.2rem;
  }

  .single .info p.createdat {
    font-size: 1.2rem;
  }

  #app .flex-container {
    display: flex;
    flex-direction: row;
  }
  
  .topic .tier-2 h5 {
    margin: 0.25rem 0;
  }

  .topic .tier-2 h5 span {
    display: inline;
    margin: 0 0.5rem;
  }

  .topic .tier-2 h5 {
    display: block;
  }

  /* mobile portrait and up */
@media screen and (min-width: 360px) {

  #app .flex-container {
    flex-direction: column;
  }

  .comment-number {
    font-size: 1.2rem;
  }

  .single .info , .topic .tier-2 h5 {
    font-size: 1.6rem;
  }

  .topic .tier-2 h5 span.tier-2.heading {
    font-weight:700;
    color: #0019a5;
  }
  
  .single .info p.createdby {
    font-size: 1.2rem;
    margin: 0;
  }

  .single .info p.createdat {
    font-size: 1.2rem;
    margin: 0;
  }

  .home .topic .ui input {
    font-size: 1.6rem;
  }
}

/* Tablets and up */
@media screen and (min-width: 768px) {
  #app .flex-container {
    flex-direction: row;
  }
}

/* small desktops and up */
@media screen and (min-width: 992px) {
  .comment-number, .comment-number p {
    font-size: 1.6rem;
  }
  .single .info h5 {
    font-size: 2.0rem;
  }

  .topic .tier-2 h5 {
    font-size: 1.75rem;
  }

  .single .info p.createdby {
    font-size: 1.6rem;
    margin: 0;
  }

  .single .info p.createdat {
    font-size: 1.6rem;
    margin: 0;
  }

  .home .topic .ui input {
    font-size: 1.6rem;
  }

}

/* desktops and up */
@media screen and (min-width: 1280px) {

}

/* mid-sized desktops and up */
@media screen and (min-width: 1440px) {

}

</style>