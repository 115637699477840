<template>
    <div class="add-comment">
        
        <button v-if="!showForm" @click="showForm = true">Add comment</button>
        <form v-if="showForm" @submit.prevent="handleSubmit">
            <div v-if="showForm" class="closer" @click="handleClose">X</div>
            <h4>Add a new comment</h4>
            <input type="text" placeholder="Type comment here..." required v-model="title">
            <div v-if="user.name" class="author-fixed">Author: {{ user.name }}</div>
            <!-- <input type="text" placeholder="Author" required v-model="user.name"> -->
            <button>Add</button>

        </form>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import useDocument from '@/composables/useDocument'
import { useUserStore } from '@/composables/user.js'
// import { timestamp } from '@/firebase/config'

export default {
    props: ['topic'],
    setup(props) {
        const title = ref('')
        const author = ref('')
        const showForm = ref(false)
        const { updateDoc } = useDocument('topics', props.topic.id)

        let user = useUserStore()
        // user.getUser()

        const handleSubmit = async () => {
                  
            const getNow = () => {
               const dateTime = new Date().toGMTString('en-us', { weekday:"long", year:"numeric", month:"short"}) // "Jul 2021 Friday"
                return dateTime;
            }

            let now = getNow()

            const newComment = {
                title: title.value,
                author: user.name,
                id:Math.floor(Math.random() * 1000000),
                createdAt: now
            }
            const res = await updateDoc({
                comments: [...props.topic.comments, newComment]
            })
            // console.log(newComment)
            title.value = ''
            author.value = ''
            showForm.value = false
        }

        const handleClose = () => {
            // console.log("close comment")
            showForm.value = false
        }

        return { title, author, showForm, handleSubmit, handleClose, user }
    }
}
</script>

<style scoped>
    .add-comment {
        text-align: center;
        margin-top: 40px;
    }
    form {
        max-width: 100%;
        text-align: left;
    }

    div.author-fixed{
        font-size: 13.333px;
        border: 0;
        border-bottom: 1px solid var(--secondary);
        padding: 10px;
        outline: none;
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 20px auto;
    }
</style>