import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag-next";

let pinia = createPinia()

// global styles
import './assets/main.css'

let app

    if(!app){
       app = createApp(App).use(router)
       app.use(VueGtag, {
        property: { id: "G-HR9CTLGL3V" }
      })
       app.use(pinia)
       app.mount('#app')

      //  console.log(app)
      //  console.log(pinia)
    }
