<template>
    <!-- <div v-if="error" class="error">{{ error }}</div> -->
    <div v-if="topic" class="topic-details container-fluid">
      <div class="row row--narrow align-center">
          <div class="col col-xs-12 col-sm-7 col-md-7 col-lg-7">
          </div>
          <div class="columns small-12">
          <!-- Topic information -->
          <div class="topic-info">
              <div class="cover">
                  <img :src="topic.coverUrl" alt="">
              </div>
              <div class="btnbar">
                <button class="nextlast lastbtn" @click="getLast()">Last</button>
                <button class="nextlast nextbtn" @click="getNext()">Next</button>
              </div>
              <h2>{{ topic.title }}</h2>
              <p class="username">Created by {{ topic.userName }}</p>
              <p class="created-at">{{ topic.createdAt }}</p>
              <p class="description">{{ topic.description }}</p>
              <Youtube v-if="topic.youtuberef" :topic="topic" />
              <div class="button-box">
                <button v-if="isAdmin()" @click="handleDelete">Delete topic</button>
                <button v-if="isAdmin()" @click="handlePromote">Promote topic</button>
                <Download v-if="topic.dload" :topic="topic" />
              </div>
          </div>
          
          <!-- comments -->
          <AddComment :topic="topic" />
          <div class="comment-lst">
            <div class="comments">Comments</div>
              <div v-if="!topic.comments.length">No comments have been added to this Topic yet.</div>
              <div class="single-comment" v-for="comment in topic.comments" :key="comment.id">
                  <div class="details">
                      <p class="the-comment">{{ comment.title }}</p>
                      <p>{{ comment.author }} : {{ comment.createdAt }}</p>
                  </div>
                   <div class="col-md-12 text-right">
                    <button v-if="isAdmin()" @click="handleClick(comment.id)">Delete comment</button>
                   </div>
              </div>
              <AddComment :topic="topic" />
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import AddComment from '@/components/AddComment'
import Download from '@/components/Download'
import Youtube from '@/components/Youtube'
import useStorage from '@/composables/useStorage'
import useDocument from '@/composables/useDocument'
import getDocument from '@/composables/getDocument'
import { computed } from '@vue/reactivity'
import { useRouter, useRoute } from 'vue-router'
import { ref, toRef } from 'vue'
import { useUserStore } from '@/composables/user.js'
import getCollection from '@/composables/getCollection'
import { watch } from '@vue/runtime-core'

export default {
    components: { AddComment, Download, Youtube },
    props: ['id'],
    setup(props) {

        const { deleteDoc } = useDocument('topics', props.id)
        const { deleteImage } = useStorage()
        const router = useRouter()
        const { updateDoc } = useDocument('topics', props.id)

        const isAdminUsr = ref(false)
        let user = useUserStore()
        let docsNext = ref([])
        let docsLast = ref([])
        let newDoc = ref([])
        let useDoc = ref([])
  
        let errFirst = ref()
        
        const route = useRoute()
        const userData = ref()

        const { error, document: topic, go } = getDocument()
        go('topics', props.id)

        // fetch the topic id when params change
        watch(
          () => route.params.id,
          (newValue, oldValue) => {
            // newValue === oldValue
            go('topics', newValue)
          },
          { deep: true }
        )

        const isAdmin = () => {
          // console.log('user pword = ',user.pword)
          if(user.role=='xadmin'){
            isAdminUsr.value = true
            return true
          }
        }

        const getNow = () => {
            const timestampnow = Date.now()
            return timestampnow;
        }

        const ownership = computed(() => {
            return topic.value
        })

        const handleDelete = async () => {
            await deleteImage(topic.value.filePath)
            await deleteDoc()
            router.push({ name: 'Home' })
        }

        const handleClick = async (id) => {
            const comments = topic.value.comments.filter((comment) => comment.id != id)
            await updateDoc({ comments })
        }

        const handlePromote = async () => {
          let now = getNow()
          await updateDoc({ datetimeChanged: now })
          if(!error.value) {
                // console.log('user logged in')
                router.push({ name: 'Home' })
            }
        }

        const getNext = () => {
            // debugger
            let where = ['datetimeChanged', '>', topic.value.datetimeChanged]
            const { error:err1, documents: docsNext, ids, load } = getCollection('topics',where,'datetimeChanged','asc',1)
            load()
            watch(
              () => ids,
              (newValue, oldValue) => {
                // newValue === 
                // debugger
                if(!err1.value) {
                  router.push({ name: 'TopicDetails', params: { id: newValue.toString() } })
                }
              },
              { deep: true }
            )

        }

        const getLast = () => {
            // debugger
            let where = ['datetimeChanged', '<', topic.value.datetimeChanged]
            const { error:err2, documents: docsLast, ids, load } = getCollection('topics',where,'datetimeChanged','desc',1)
            load()
            
            watch(
              () => ids,
              (newValue, oldValue) => {
                // newValue === oldValue
                if(!err2.value) {
                  router.push({ name: 'TopicDetails', params: { id: newValue.toString(),c: 1 } })
                }
              },
              { deep: true }
            )
  
        }
// console.log("topic", topic)
        return { error, topic, ownership, handleDelete, handleClick, handlePromote, getLast, getNext, useDoc, isAdmin, user }
    }
}
</script>

<style>
    .btnbar{
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1rem;
    }
    .nextlast{
      justify-content: space-between;
    }

   .closer { 
    cursor: pointer;
    font-weight: 900;
    font-size: 2rem;
    margin-left: auto;
    float:right;
    padding: 2px 8px;
    border: 1px solid #ebebeb;
  }

  .topic-details {
    color: #032b5a;
  }

  .cover {
    overflow: hidden;
    border-radius: 0;
    position: relative;
    padding: 0;
    width: 70%;
    margin: 0 auto;
    height: auto;
  }
  .cover img {
    height: auto;
    -ms-interpolation-mode: bicubic;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    width: 100%;
  }
  .topic-info {
    text-align: center;
  }
  .topic-info h2 {
    text-transform: capitalize;
    font-size: 2rem;
    margin-top: 30px;
  }
  .topic-info p {
    margin-bottom: 4px;
  }

  #app .content .row--narrow {
      max-width: 61.25rem;
  }

  #app .content .row {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .align-center {
      -ms-flex-pack: center;
      justify-content: center;
  }

  .username, .created-at {
    color: #032b5a;
  }

  .description {
    color: #032b5a;
    text-align: left;
    margin: 34px 0;
    border: 0;
    border-bottom: 1px solid var(--secondary);
    border-top: 1px solid var(--secondary);
    padding: 20px 0;
    display: block;
  }

  .button-box {
    margin-top: 28px;
  }

  .comments{
    color: #032b5a;
    border-bottom: 1px solid var(--secondary);
    margin-top: 30px;
    font-size: 1.8rem;
  }

  .comment-lst {
    margin-top: 30px;
  }

  .single-comment{
    color: #032b5a;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--secondary);
      margin-bottom: 20px;
  }

 .single-comment .details p.the-comment {
    font-size: 1.8rem;
    color: #032b5a;
 }

 /* mobile portrait and up */
@media screen and (min-width: 360px) {

  .cover {
    width: 80%;
    height: auto;
  }

  .single-comment .details p.the-comment  {
    color: #032b5a;
    font-size: 1.8rem;
    line-height: 1.5;
  }

  .single-comment .details p {
    color: #032b5a;
    font-size: 1.4rem;
  }

  .comments{
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.8rem;
  }

  .topic-info h2 {
    font-size: 2.4rem;
    color: #032b5a;
  }

  .topic-info .username, .topic-info .created-at {
    color: #032b5a;
    font-size: 1.3rem;
    margin: 0;
  }

  button {
    font-size: 2.4rem;
  }

}

/*  Mobiles - landscape  */
@media only screen and (min-device-width: 360px) and (orientation: landscape) {
  .cover {
    width: 60%;
    height: 60vh;
  }
}

/* Tablets and up */
@media screen and (min-width: 768px) {

}

/* small desktops and up */
@media screen and (min-width: 992px) {
  .cover {
    width: 70%;
    height: 40vh;
  }
  .cover img {
    height: auto;
    width: 100%;
  }
}

/* desktops and up */
@media screen and (min-width: 1280px) {

}

/* mid-sized desktops and up */
@media screen and (min-width: 1440px) {

}

</style>