<template>
  <div class="navbar">
      <nav class="row">
          <div class="col col-xs-12 col-sm-7 col-md-7 col-lg-67">
            <h1><router-link :to="{ name: 'Home' }">Franchise Marketing Forum</router-link></h1>
          </div>
          <div class="links col col-xs-12 col-sm-5 col-md-5 col-lg-5 text-right">
              <div>
                <!-- <button @click="gottoPage()">Go to page</button> -->
                <!-- <router-link :to="{ name: 'CreateTopic' }" v-if="isAdmin()">Create New Topic</router-link> -->

                <router-link
                  :to="{ name: 'CreateTopic' }" v-if="isAdmin()"
                  custom
                  v-slot="{ navigate }"
                >
                  <button
                    @click="navigate"
                    role="link"
                  >
                    Create New Topic
                  </button>
                </router-link>
                <router-link
                  :to="{ name: 'Home' }"
                  custom
                  v-slot="{ navigate }"
                >
                  <button
                    @click="navigate"
                    role="link"
                  >
                    Back to forum
                  </button>
                </router-link>

                <!-- <span>Welcome back, {{ user.name }}</span> -->
              </div>
          </div>
      </nav>
  </div>
</template>

<script>

import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useUserStore } from '@/composables/user.js'

export default {
    setup() {
        const isAdminUsr = ref(false)
        const router = useRouter()
        let user = useUserStore()

        const isAdmin = () => {
            // console.log("user", [user])
          if(user.role=='xadmin'){
            isAdminUsr.value = true
            return true
          }
        }

        const gottoPage = () => {
          // console.log('here 1')
            let hrefHome = "http://localhost.o2portalhubv2/"
            window.open(hrefHome);
        }

        return { router, user, isAdmin, gottoPage }

    }
}
</script>

<style scoped>
  .navbar {
    color: #ffffff;
    padding: 16px 10px;
    margin-bottom: 40px;
    background: #0019a5;
  }
  .navbar h1 a {
    font-size: 4rem;
  }

  nav {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  nav img {
    max-height: 60px;
  }
  nav h1 {
    margin-left: 0;
    color: #ffffff;
  }
  nav h1 a, nav .links a{
    color: #ffffff;
    text-decoration: none;
  }
  
  nav h1 a{
     display: block;
  }
  
  nav h1 a:hover{
    color: #ffffff;
    opacity: 0.8;
  }

  nav .links {
    margin-left: auto;
  }
  nav .links a, button {
    margin-left: 0;
    font-size: 14px;
  }
  nav img {
    max-height: 60px;
  }
  span {
    font-size: 14px;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #eee;
    color: #ffffff;
  }


#app .navbar button, #app .btn { 
  background-color: #01b7b4;
  border-color: #01b7b4;
  color: #ffffff;
}
#app .navbar button:hover, #app .btn:hover {
  background: #ffffff;
  color: #0019a5;
  border-color: #ffffff;
}
#app .navbar button:disabled {
  opacity: 0.5;
  color: var(--primary);
  background: var(--secondary);
  cursor: not-allowed;
}

  /* mobile portrait and up */
  @media screen and (min-width: 360px) {
    span, nav h1 a, nav .links a, button {
      font-size: 1.8rem;
    }
  }

  /*  Mobiles - landscape  */
  @media only screen and (min-device-width: 360px) and (orientation: landscape) {

    #app .navbar button, #app .btn { 
        background-color: #01b7b4;
        border-color: #01b7b4;
        color: #ffffff;
      }
      #app .navbar button:hover, #app .btn:hover {
        background: #ffffff;
        color: #0019a5;
        border-color: #ffffff;
      }
      #app .navbar button:disabled {
        opacity: 0.5;
        color: var(--primary);
        background: var(--secondary);
        cursor: not-allowed;
      }

  }

  /* Tablets and up */
  @media screen and (min-width: 768px) {

  }

  /* small desktops and up */
  @media screen and (min-width: 992px) {
    span {
        font-size: 14px;
    }
    nav .links a, button {
      font-size: 2.4rem;
      background: #01b7b4;
    }
  }

  /* desktops and up */
  @media screen and (min-width: 1280px) {

  }

  /* mid-sized desktops and up */
  @media screen and (min-width: 1440px) {

  }

</style>