<template>
<div class="vplayer">
    <div v-if="youtuberef" class="embed-responsive embed-responsive-16by9">
        <iframe
            width="560" 
            height="315" 
            :src="youtuberefmod"
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen="">
        </iframe>
    </div>
    <p v-else></p>
</div>
    
</template>

<script>
import { ref } from '@vue/reactivity'

export default {

    props: ['topic'],
    setup(props) {
        const youtuberef = props.topic.youtuberef
        const youtuberefmod =  "https://www.youtube.com/embed/" + youtuberef
        // console.log("youtuberef ",props.topic.youtuberef)

        return { youtuberef, youtuberefmod }
    }

}
</script>

<style>
    .vplayer{
        width: 60%;
        margin: 2rem auto 0;
    }
</style>