<template>
  <div class="home">
    <div class="row">
      <div class="col col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h3 class="text-left" v-if="user.name">Welcome back {{ user.name }}</h3>
      </div>
    </div>
      <div class="topic">
      <div v-if="error" class="error">{{ error }}</div>
      <div v-if="documents">
        <listView :topics="documents" />
      </div>
      <!-- <button v-if="itemsLeft" @click="getLast()">Last</button>
      <button v-if="itemsLeft" @click="getNext()">Next</button> -->
  </div>
  </div>
</template>

<script>
import getCollection from '../composables/getCollection'
import listView from '../components/ListView.vue'
import { useUserStore } from '../composables/user.js'
import urlStripped from '../composables/urlStripped.js'

import { ref, onMounted } from 'vue'
import { watch } from '@vue/runtime-core'

export default {
  components: { listView },
  name: 'Home',
  setup() {
        const { newDocs, addThumbs } = urlStripped()
        const { error, documents, load } = getCollection('topics',null,'datetimeChanged','desc')

        load()
        // console.log("load")
        let user = useUserStore()

        onMounted(() => {
        // console.log("mounted")

          user.getUser().then((response) => {
            // console.log("response.data = ", [response])
            user = response

            // console.log("user 3 =", user)
            // return { user }
          })
        })



    watch(documents, () => {
      const what = addThumbs(documents)
      // debugger
    })

    return { error, documents, newDocs, user }
  }
}
</script>

<style scoped>
  #app .content .home h3 {
      color: #0019a5;
  }


  /* mobile portrait and up */
  @media screen and (min-width: 360px) {

    #app .content .home h3 {
      font-size: 2rem;
    }
  }

  /* Tablets and up */
  @media screen and (min-width: 768px) {

  }

  /* small desktops and up */
  @media screen and (min-width: 992px) {
    .content .home h3 {
      font-size: 2.2rem;
    }
  }

  /* desktops and up */
  @media screen and (min-width: 1280px) {

  }

  /* mid-sized desktops and up */
  @media screen and (min-width: 1440px) {

  }
</style>
