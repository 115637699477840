<template>
<Navbar />
<div class="content">
    <router-view/>
</div> 
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  components: { Navbar }
}

</script>


<style>

  p {
    padding: 0;
  }

  a:hover{
    text-decoration: none;
  }

  #app{
    margin-bottom: 4rem;
    padding-bottom: 4rem;
  }
  #app .content{
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 0 4rem;
  }

  /* mobile portrait and up */
  @media screen and (min-width: 360px) {
    #app .content {
      padding: 0 1rem;
    }
  }

  /* Tablets and up */
  @media screen and (min-width: 768px) {

  }

  /* small desktops and up */
  @media screen and (min-width: 992px) {
    #app .content {
      padding: 0 2.2rem;
    }
  }

  /* desktops and up */
  @media screen and (min-width: 1280px) {

  }

  /* mid-sized desktops and up */
  @media screen and (min-width: 1440px) {

  }

</style>
