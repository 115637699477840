import { ref, watchEffect, reactive } from 'vue'
import { projectFirestore } from '../firebase/config'

const getCollection = (collection, query, orderByField, upOrDown, limitBy) => {

  const documents = ref(null)
  const ids = reactive(new Array())

  const error = ref(null)
 
  const load = async () => {

    orderByField = orderByField === null ? 'createdAt' : orderByField;
    upOrDown = upOrDown === null ? 'asc' : upOrDown;
    
    // register the firestore collection reference
    let collectionRef = projectFirestore.collection(collection)
      .orderBy(orderByField, upOrDown) // ,limit(3))

      if(query) {
        // console.log("query ", query)
        collectionRef = collectionRef.where(...query)
      }

      if(limitBy) {
        collectionRef = collectionRef.limit(limitBy)
      }

    const unsub = collectionRef.onSnapshot(snap => {
      let results = []
      snap.docs.forEach(doc => {
        // must wait for the server to create the timestamp & send it back
        doc.data().createdAt && results.push({...doc.data(), id: doc.id})
        ids.push(doc.id)
      });
      
      // update 
      
      // console.info("id here", ids[0])

      documents.value = results
      error.value = null
    }, err => {
      // console.log(err.message)
      documents.value = null
      error.value = 'could not fetch the data'
    })

    watchEffect((onInvalidate) => {
      onInvalidate(() => unsub());
    });
  }
  return { error, documents, ids, load }
}

export default getCollection