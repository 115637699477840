<template>
    <button v-if="dload" @click="clickedDownload">Download File</button>
    <p v-else></p>
</template>

<script>
import { ref } from '@vue/reactivity'

export default {

    props: ['topic'],
    setup(props) {
        const dload = props.topic.dload
        // const dload = "https://res.cloudinary.com/tc-communications/image/upload/v1646999001/103440.05_O2Open_MultisaveGuide.pdf"
        // console.log("dload ",props.topic)
        const clickedDownload = async () => {

            const fileName = dload.substring(dload.lastIndexOf('/') + 1);
            // console.log(fileName); // 👉️ index.html

              try {
                const response = await fetch(dload)
                const blob = await response.blob();
                const url = await URL.createObjectURL(blob)

                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } catch(err) {
                console.log({ err })
            }
        }

        return { dload, clickedDownload }
    }

}
</script>

<style>

</style>