<template>
<form @submit.prevent="handleSubmit">
    <h4>Create New Topic</h4>
    <input type="text required" placeholder="Topic title" v-model="title">
    <textarea required placeholder="Topic description..." v-model="description"></textarea>
    <input type="text required" placeholder="Link to asset download (from cloudinary)" v-model="dload">
    <input type="text required" placeholder="YouTube video reference example: lovTqjUFz1k" v-model="youtuberef">
    <!-- upload topic image -->
    <label>Upload topic image</label>
    <input type="file" @change="handleChange">
    <div class="error">{{ fileError }}</div>
    <button v-if="!isPending">Create</button>
    <button v-else disabled>Saving...</button>
</form>
</template>

<script>
import { ref } from '@vue/reactivity'
import useStorage from '@/composables/useStorage'
import useCollection from '@/composables/useCollection'
// import { timestamp } from '@/firebase/config'
import { useRouter } from  'vue-router'
import { useUserStore } from '@/composables/user.js'

export default {
    setup() {
        const { filePath, url, uploadImage } = useStorage()
        const { error, addDoc } = useCollection('topics')
        const router = useRouter()
        const title = ref('')
        const description = ref('')
        const dload = ref('')
        const youtuberef = ref('')
        const file = ref(null)
        const fileError = ref(null)
        const isPending = ref(false)

        let user = useUserStore()
        // user.getUser()
    
        const getNow = () => {
            const dateTime = new Date().toGMTString('en-us', { weekday:"long", year:"numeric", month:"short"}) // "Jul 2021 Friday"
            return dateTime;
        }

         const getTimeStampNow = () => {
            const timestampnow = Date.now()
            return timestampnow;
        }

        let now = getNow()
        let tsNow = getTimeStampNow()

        const handleSubmit = async () => {
            if(file.value){
                isPending.value = true
                await uploadImage(file.value)
                const res = await addDoc({
                    title: title.value,
                    description: description.value,
                    dload: dload.value,
                    youtuberef: youtuberef.value,
                    userName: user.name,
                    userId: user.id_user,
                    coverUrl: url.value,
                    filePath: filePath.value,
                    comments: [],
                    createdAt: now,
                    datetimeChanged: tsNow
                })
                isPending.value = false
                
                if(!error.value) {
                    // console.log('Topic added')
                    router.push({ name: 'TopicDetails', params: { id: res.id } })
                }
                // console.log(title.value, description.value, file.value)
                // console.log('image uploaded, url: ', url.value)
            }
        }

        // allowed file types
        const types = ['image/png', 'image/jpeg']
        
        const handleChange = (e) => {
            const selected = e.target.files[0]
            // console.log(selected)

            if(selected && types.includes(selected.type)){
                file.value = selected
                fileError.value = null
            } else {
                file.value = null
                fileError.value = "Please select an image file (png or jpg)"
            }
        }

        return { title, description, dload, youtuberef, handleSubmit, handleChange, fileError, isPending, user }
    }
}
</script>

<style>
form {
    background: white;
  }
  input[type="file"] {
    border: 0;
    padding: 0;
  }
  label {
    font-size: 12px;
    display: block;
    margin-top: 30px;
  }
  button {
    margin-top: 20px;
  }
  
</style>