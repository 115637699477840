<template>
<h1>Sorry this page does not exist.</h1>
<router-link :to="{ name: 'Home' }">Go back to the Franchise Marketing Forum home page</router-link>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style>

</style>